import { FeatureFlagged } from '@backstage/core-app-api';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasSubcomponentsCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  EntityKubernetesContent,
  isKubernetesAvailable,
} from '@backstage/plugin-kubernetes';
import { EntityCortexContent } from '@cortexapps/backstage-plugin';
import {
  EntityAnimalsDeploymentsContent,
  isAnimalsProjectIDAnnotationAvailable,
} from '@efg/plugin-animals-deploy';
import {
  isRoadmapAnnotationAvailable,
  TechdocRoadmapsAndGoldenpathViewerPage,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';
import { EntityGitlabContent } from '@immobiliarelabs/backstage-plugin-gitlab';
import { EntityIncidentCard } from '@incident-io/backstage';
import { Grid } from '@material-ui/core';
import {
  BackstagePluginKubecostPage,
  isKubecostAvailable,
} from '@suxess-it/backstage-plugin-kubecost';
import React from 'react';
import { CicdContent } from '../../../EntityContent/CicdContent';
import { overviewContent } from '../../../EntityContent/DefaultOverviewContent';
import { techdocsContent } from '../../../EntityContent/TechdocsContent';
import { entityRoutes } from '../../../EntityNavDropdown/EntityDropdownRoutes';

export const ServiceEntityPage = () => {
  const { entity } = useEntity();
  const hasSubcomponents = entity?.relations?.some(
    relation => relation.type === 'hasPart',
  );

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/cortex" title="Cortex">
        <EntityCortexContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isKubernetesAvailable}
        path="/kubernetes"
        title="Kubernetes"
      >
        <EntityKubernetesContent refreshIntervalMs={30000} />
      </EntityLayout.Route>

      <FeatureFlagged with="kubecost">
        <EntityLayout.Route
          if={isKubecostAvailable}
          path="/kubecost"
          title="Cost"
        >
          <BackstagePluginKubecostPage />
        </EntityLayout.Route>
      </FeatureFlagged>
      {entityRoutes(
        [
          {
            path: '/incidents',
            title: 'Incidents',
            content: (
              <Grid item md={6}>
                <EntityIncidentCard />
              </Grid>
            ),
          },
          {
            path: '/ci-cd',
            title: 'CI/CD',
            content: <CicdContent />,
          },
          {
            path: '/repo-info',
            title: 'Repo Info',
            content: <EntityGitlabContent />,
          },
          {
            path: '/animals-cd',
            title: 'Deploy History',
            content: <EntityAnimalsDeploymentsContent />,
            shouldRender: isAnimalsProjectIDAnnotationAvailable(entity),
          },
          {
            path: '/api',
            title: 'APIs',
            content: (
              <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                  <EntityProvidedApisCard />
                </Grid>
                <Grid item md={6}>
                  <EntityConsumedApisCard />
                </Grid>
              </Grid>
            ),
          },
          {
            path: '/dependencies',
            title: 'Dependencies',
            content: (
              <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                  <EntityDependsOnComponentsCard variant="gridItem" />
                </Grid>
                <Grid item md={6}>
                  <EntityDependsOnResourcesCard variant="gridItem" />
                </Grid>
              </Grid>
            ),
          },
          {
            path: '/roadmap',
            title: 'Roadmap',
            content: <TechdocRoadmapsAndGoldenpathViewerPage />,
            shouldRender: isRoadmapAnnotationAvailable(entity),
          },
          {
            path: '/relations',
            title: 'Relations',
            content: (
              <>
                <Grid item md={12} xs={12}>
                  <EntityCatalogGraphCard variant="gridItem" height={300} />
                </Grid>
                {hasSubcomponents && (
                  <Grid item md={8} xs={12}>
                    <EntityHasSubcomponentsCard variant="gridItem" />
                  </Grid>
                )}
              </>
            ),
          },
        ].filter(route => route.shouldRender !== false),
      )}
    </EntityLayout>
  );
};
